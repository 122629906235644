import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { Tag } from "@/components/Tag";

const BlogTags: FC<PageProps<GatsbyTypes.Query>> = ({ data }) => {
  const distinctTags = data.allContentfulNewsPost.edges
    .map(({ node }) => node.tags)
    .flat()
    .filter((tag, index, tagArray) => tagArray.indexOf(tag) === index);

  return (
    <ul className="l-with-gutters-mobile">
      {distinctTags.sort().map((tag, index) => (
        <li key={index}>
          <Tag tag={tag} unstyled />
        </li>
      ))}
    </ul>
  );
};

export default BlogTags;

export const query = graphql`
  {
    allContentfulNewsPost(filter: { url: { ne: null }, tags: { ne: null } }) {
      edges {
        node {
          tags
        }
      }
    }
  }
`;
